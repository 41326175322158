import {
  CellEditingStoppedEvent,
  CellValueChangedEvent,
  ColDef,
  GetRowIdFunc,
  ProcessDataFromClipboardParams,
  RowClassParams,
  RowNode,
  RowSelectionOptions,
  SelectionChangedEvent,
  SelectionColumnDef,
  ValueParserFunc
} from '@ag-grid-community/core'
import {
  AgGridReact,
  CustomCellEditorProps,
  CustomCellRendererProps
} from '@ag-grid-community/react'
import { faCopy } from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import dayjs from 'dayjs'
import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import ButtonWithFeedback from '../../components/ButtonWithFeedback/ButtonWithFeedback'
import ComponentHeader from '../../components/ComponentHeader/ComponentHeader.tsx'
import { finHeaderButtons } from '../../components/ComponentHeader/helpers.tsx'

import { useOpenFin } from '../../app/openFinContext.tsx'
import Modal from '../../components/GenericModal/Modal.tsx'
import gridStyles from '../../components/Grid/grid.module.scss'
import NewWatchListInput from '../../components/Upload/NewWatchListInput'
import {
  clearCreateListTradingListError,
  createListTradingList,
  resetListTradingTransactionId,
  setListTradingWatchlistId
} from '../../store/listTrading/actions'
import {
  getErrorStatus,
  getListTradingTransactionId
} from '../../store/listTrading/selectors'
import { ListTradingOrder } from '../../store/listTrading/types'
import { useAppDispatch, useAppSelector } from '../../store/types.ts'
import { getCurrentTheme } from '../../store/userPreferences/selectors.ts'
import { fetchWatchListsAction } from '../../store/watchList/actions'
import { getWatchList } from '../../store/watchList/selectors'
import { CUSIP } from '../BondList/columnDefs'
import { BuySellSwitch } from './cells/ToggleSwitch'
import styles from './grid.module.scss'
import tradingStyles from './styles.module.scss'

type GridType = Partial<ListTradingOrder> & { id: number; error?: string }

const getRowId: GetRowIdFunc<GridType> = ({ data }) => {
  return `${data?.id || ''}`
}

const createEmptyRow = (id: number) => ({ id })
const createEmptyRows = (count: number, startId: number = 0) => {
  const blankArray = new Array(count).fill('')
  return blankArray.map((_emptyString, i) => createEmptyRow(i + startId))
}
const createRowFromData = (id: number, data: string[]): GridType => {
  const trimmed = data.map((s) => s.trim())
  const row = createEmptyRow(id) as GridType
  const [cusipOrIsin, bidOfferText, size, price] = trimmed
  row.cusipOrIsin = cusipOrIsin?.toUpperCase()
  row.isBid = bidOfferText?.length
    ? ['b', 'buy', 'offer'].includes(bidOfferText.toLowerCase())
    : undefined
  row.size =
    size === undefined || size === ''
      ? undefined
      : parseInt(size.replace(/,/g, ''), 10)
  row.price =
    price === undefined || price === '' ? undefined : parseFloat(price)
  return row
}

const isBidParser: ValueParserFunc<GridType, GridType['isBid']> = ({
  newValue
}) => {
  const lcValue = newValue.toLowerCase()
  return ['b', 'buy', 'offer'].includes(lcValue)
}

const dateFormat = 'M/DD/YY, h:mm a'

const hasOrderData = (data: GridType) => data.cusipOrIsin

const IsBidRenderer = ({ data }: CustomCellRendererProps<GridType>) => {
  if (!data || !data.cusipOrIsin || data.isBid === undefined) return null
  return <BuySellSwitch securityId={data.id} isBid={!!data.isBid} />
}

const IsBidEditor = ({
  data,
  value,
  onValueChange
}: CustomCellEditorProps<GridType>) => {
  useEffect(() => {
    // edit on first click
    if (data?.cusipOrIsin) {
      onValueChange(!data.isBid)
    }
  }, [])
  if (!data || !data.cusipOrIsin) return null
  return (
    <BuySellSwitch
      securityId={data.id}
      isBid={value}
      onChange={onValueChange}
    />
  )
}

const spreadToggleId = 'new-watchlist-is-spread'
const duplicateSymbolError = 'Duplicate Cusip or ISINs.'

const rowSelection: RowSelectionOptions = {
  enableClickSelection: false,
  isRowSelectable: ({ data }: RowNode<GridType>) => !!data?.cusipOrIsin,
  mode: 'multiRow'
}

const selectionColDef: SelectionColumnDef = {
  maxWidth: 25
}
const CreateTradingList = () => {
  const { fin, replaceFinWindow } = useOpenFin()
  const [rows, setRows] = useState<GridType[]>(createEmptyRows(1))
  const [selectedRows, setSelectedRows] = useState<GridType[]>([])
  const [watchlistName, setWatchlistName] = useState(dayjs().format(dateFormat))

  const dispatch = useAppDispatch()

  const theme = useAppSelector(getCurrentTheme)

  // ------------------ max exceeded modal ------------------ //

  const [maxExceeded, setMaxExceeded] = useState(false)
  useEffect(() => {
    if (rows.length > 200) setMaxExceeded(true)
  }, [rows.length])
  const closeModal = useCallback(() => {
    setMaxExceeded(false)
  }, [])

  // ------------------ validation ------------------ //
  const hasErrors = rows.some((row) => row.error)
  const errMsg = useAppSelector(getErrorStatus)
  const [rowsChanged, setRowsChanged] = useState(false)

  const validateRows = useCallback(() => {
    const counts = rows.reduce((map, row) => {
      if (!row.cusipOrIsin) return map
      const prevCount = map[row.cusipOrIsin.toUpperCase()] ?? 0
      return { ...map, [row.cusipOrIsin.toUpperCase()]: prevCount + 1 }
    }, {} as Record<string, number>)
    const getError = (row: GridType) => {
      if (!row.cusipOrIsin) {
        return
      }
      if (counts[row.cusipOrIsin.toUpperCase()] > 1) {
        return duplicateSymbolError
      }
      if (typeof row.isBid !== 'boolean') {
        return 'Please specify a direction.'
      }
      if (typeof row.size === 'number' && row.size % 1) {
        return 'Size must be a whole number.'
      }
      if (errMsg && new RegExp(`\\b${row.cusipOrIsin}\\b`, 'i').test(errMsg)) {
        return 'Cusip or ISIN not found.'
      }
    }
    const verifiedRows = rows.map((row) => {
      return { ...row, error: getError(row) }
    })
    setRows(verifiedRows)
  }, [rows, errMsg])

  useEffect(() => {
    validateRows()
    setRowsChanged(false)
  }, [rowsChanged, errMsg])

  const errorStyle = useCallback(
    ({ data: order }: RowClassParams<GridType>) => {
      return !!order?.error
    },
    []
  )

  const rowClassRules = useMemo(() => {
    return {
      [styles.error]: errorStyle
    }
  }, [errorStyle])

  const removeInvalidRows = useCallback(() => {
    setRows((oldRows) => {
      return oldRows.filter((row) => {
        const firstDupe = oldRows.find(
          (r) => r.cusipOrIsin?.toUpperCase() === row.cusipOrIsin?.toUpperCase()
        )
        return (
          !row.error ||
          (row.error === duplicateSymbolError && firstDupe === row)
        )
      })
    })
    dispatch(clearCreateListTradingListError())
    setRowsChanged(true)
  }, [rows])

  // ------------------ header controls ------------------ //
  const [isSpread, setIsSpread] = useState(true)
  const onIsSpreadChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    setIsSpread(checked)
  }, [])

  const rowsAreSelected =
    selectedRows.filter((row) => hasOrderData(row)).length > 0
  const disableSubmit = !rows.some(hasOrderData) || rows.length > 200
  const [finishDeletingRows, setFinishDeletingRows] = useState(false)

  const deleteSelectedRows = useCallback(() => {
    setFinishDeletingRows(true)
    const agRows = gridRef.current?.api.getSelectedRows()
    setRows((oldRows) => {
      const filteredRows = oldRows.filter((row) => !agRows!.includes(row))
      const extraRow = filteredRows.length === 0 ? createEmptyRows(1) : []
      return [...filteredRows, ...extraRow]
    })
    setSelectedRows([])
    setRowsChanged(true)
    dispatch(clearCreateListTradingListError())
  }, [selectedRows, dispatch])

  useEffect(() => {
    if (finishDeletingRows) {
      gridRef.current?.api.setGridOption('rowData', rows)
      setFinishDeletingRows(false)
    }
  }, [finishDeletingRows, rows])

  const onSubmit = useCallback(() => {
    // last ditch error checking for typing into cells
    // bc we can't reliably detect when they have "left" the row when typing
    if (rows.some((r) => r.cusipOrIsin && typeof r.isBid !== 'boolean')) {
      setRowsChanged(true)
      return
    }
    const dataRows = rows
      .filter((row) => !!row.cusipOrIsin)
      .map((row) => ({ ...row, isSpread }))
    dispatch(
      createListTradingList(watchlistName, dataRows as ListTradingOrder[])
    )
  }, [rows, isSpread, errMsg])

  // ------------------ grid interaction ------------------ //
  const [selectAddedRows, setSelectAddedRows] = useState(false)

  const processPastedData = useCallback(
    ({ data, api }: ProcessDataFromClipboardParams<GridType>) => {
      const focusedCell = api.getFocusedCell()
      if (!focusedCell) return data
      const rowIndex = focusedCell.rowIndex
      const startId = Math.max(0, ...rows.map((r) => r.id))
      const rawRows = data.filter((r) => r.join('').trim())
      const processedRows = rawRows.map((row, i) =>
        createRowFromData(startId + i, row)
      )
      const newIndex = processedRows[processedRows.length - 1].id + 1
      processedRows.push(createEmptyRow(newIndex))
      setRows([...rows.slice(0, rowIndex), ...processedRows])
      setSelectAddedRows(true)
      setRowsChanged(true)
      return null
    },
    [rows]
  )

  useEffect(() => {
    const api = gridRef.current?.api
    if (api && selectAddedRows) {
      api.forEachNode((node) => {
        if (node.data?.cusipOrIsin) {
          node.setSelected(true)
        }
      })
      setSelectAddedRows(false)
    }
  }, [selectAddedRows])

  const onCellValueChanged = useCallback(
    ({ data, colDef, newValue }: CellValueChangedEvent<GridType>) => {
      if (typeof colDef?.field !== 'string') return
      const fieldName = colDef.field
      const val = typeof newValue === 'string' ? newValue.trim() : newValue
      setRows((oldRows) =>
        oldRows.map((row) =>
          row.id === data.id
            ? {
                ...row,
                [fieldName]: val
              }
            : row
        )
      )
      setRowsChanged(true)
    },
    []
  )

  const onCellEditingStopped = useCallback(
    ({ rowIndex, oldValue, newValue }: CellEditingStoppedEvent<GridType>) => {
      if (rowIndex === rows.length - 1) {
        const newId = Math.max(0, ...rows.map((r) => r.id)) + 1
        const newRow = createEmptyRow(newId)
        setRows((rs) => [...rs, newRow])
      }
      if (oldValue !== newValue) {
        setRowsChanged(true)
      }
    },
    [rows]
  )

  const onSelectionChanged = useCallback(
    ({ api }: SelectionChangedEvent<GridType>) => {
      const newRows = api.getSelectedRows()
      setSelectedRows(newRows)
    },
    []
  )

  // ------------------ grid definition ------------------ //
  const classes = cx(
    styles.listTrading,
    styles.create,
    gridStyles.gridDimensions,
    gridStyles.gridStyle,
    theme
  )
  const gridRef = useRef<AgGridReact<GridType>>(null)

  const defaultColumn = useMemo(() => {
    return {
      editable: true,
      lockPinned: true,
      minWidth: 10,
      menuTabs: [],
      resizable: false,
      singleClickEdit: true,
      sortable: false,
      suppressAutoSize: true,
      suppressColumnsToolPanel: true,
      width: 60
    }
  }, [])

  const colDefs = useMemo(() => {
    return [
      {
        colId: CUSIP,
        field: 'cusipOrIsin',
        headerName: 'Cusip/Isin',
        singleClickEdit: false,
        flex: 1
      },
      {
        cellClass: cx(
          styles.centeredText,
          styles.pillCell,
          styles.pillCellSmall
        ),
        cellRenderer: IsBidRenderer,
        cellEditor: IsBidEditor,
        colId: 'isBid',
        field: 'isBid',
        editable: true,
        headerName: 'Buy(B)/Sell(S)',
        singleClickEdit: true,
        valueFormatter: ({ value }) =>
          typeof value === 'boolean' ? (value ? 'B' : 'S') : '',
        valueParser: isBidParser,
        useValueFormatterForExport: true,
        width: 95
      },
      {
        colId: 'size',
        cellDataType: 'number',
        field: 'size',
        headerName: 'Size'
      },
      {
        cellDataType: 'number',
        colId: 'price',
        field: 'price',
        headerName: `Limit ${isSpread ? 'Spread' : 'Price'}`,
        width: 90
      },
      {
        cellClass: styles.errorMsg,
        colId: 'Errors',
        editable: false,
        field: 'error',
        flex: 3,
        hide: !hasErrors
      },
      {
        colId: 'empty',
        headerName: '',
        editable: false,
        flex: 3
      }
    ] as Array<ColDef<GridType>>
  }, [isSpread, hasErrors, errorStyle])

  const copyData = useCallback(() => {
    if (!gridRef.current?.api) {
      return
    }
    const copiedData = gridRef.current.api.getDataAsCsv({
      columnKeys: [CUSIP, 'isBid', 'size', 'price'],
      columnSeparator: '\t',
      skipColumnHeaders: false,
      suppressQuotes: true
    })
    if (copiedData) {
      navigator.clipboard.writeText(copiedData.trim()).catch(console.warn)
    }
  }, [colDefs, gridRef.current?.api])

  // ------------------ detecting the new wl and navigating ------------------ //
  const watchlistTransactionId = useAppSelector(getListTradingTransactionId)
  const watchlists = useAppSelector(getWatchList)

  const navigate = useNavigate()

  useEffect(() => {
    /*
        When we create a WL, the BE doesn't immediately know the ID of the
        new WL. So we request the list of watchlists and monitor it to see if
        we can spot our new one and navigate to it.
     */
    dispatch(fetchWatchListsAction(-1))
    dispatch(setListTradingWatchlistId('new'))
    return () => {
      dispatch(resetListTradingTransactionId())
    }
  }, [])

  useEffect(() => {
    const newList = watchlists?.find(
      (wl) => wl.transactionId === watchlistTransactionId
    )

    const handleFinWindow = (id: string) => {
      replaceFinWindow(id)
      const row = createEmptyRows(1)
      gridRef.current?.api.setGridOption('rowData', rows)
      setRows(() => {
        return [...row]
      })
    }

    if (newList?.id) {
      fin
        ? handleFinWindow(`ListTrading/${newList.id}`)
        : navigate(`./${newList.id}`, { replace: true })
    }
  }, [watchlistTransactionId, watchlists])

  return (
    <div className={cx(gridStyles.outerGridContainer, styles.listTrading)}>
      <header>
        <ComponentHeader
          title="Create New List"
          headerButtons={finHeaderButtons('ListTrading')}
        />
        <div className={tradingStyles.controls}>
          <NewWatchListInput
            className={tradingStyles.nameInput}
            watchlistName={watchlistName}
            setWatchlistName={setWatchlistName}
          />
          <div className={tradingStyles.spreadToggle}>
            <span>{isSpread ? <>Price</> : <strong>Price</strong>} </span>
            <span className="pretty p-switch p-smooth">
              <input
                type="checkbox"
                name={spreadToggleId}
                id={spreadToggleId}
                data-testid={spreadToggleId}
                onChange={onIsSpreadChange}
                checked={isSpread}
              />
              <div className="state p-default">
                <label htmlFor={spreadToggleId} id={`${spreadToggleId}-label`}>
                  {isSpread ? <strong>Spread</strong> : <>Spread</>}
                </label>
              </div>
            </span>
          </div>
          {hasErrors && (
            <button
              onClick={removeInvalidRows}
              data-testid="lt-remove-invalid-rows"
            >
              Remove Invalid rows
            </button>
          )}
          {rowsAreSelected ? (
            <button
              onClick={deleteSelectedRows}
              id="create-list-trading-delete-selected"
              data-testid="create-list-trading-delete-selected"
            >
              Remove Selected
            </button>
          ) : (
            <></>
          )}
          <ButtonWithFeedback
            contentUpdate="COPIED"
            onClick={copyData}
            timerToReturnToFirstState={3000}
            title={rows.length === 1 ? 'Copy Headers' : 'Copy Data'}
          >
            <FontAwesomeIcon icon={faCopy} />
          </ButtonWithFeedback>
          <button
            onClick={onSubmit}
            id="create-list-trading-submit"
            data-testid="create-list-trading-submit"
            disabled={disableSubmit || hasErrors}
          >
            Submit
          </button>
        </div>
      </header>
      <div className={classes} data-testid="create-list-trading-list-grid">
        <AgGridReact<GridType>
          ref={gridRef}
          groupHeaderHeight={0}
          headerHeight={20}
          rowHeight={20}
          columnDefs={colDefs}
          columnMenu="legacy"
          defaultColDef={defaultColumn}
          getRowId={getRowId}
          rowData={rows}
          rowSelection={rowSelection}
          stopEditingWhenCellsLoseFocus={true}
          enterNavigatesVertically={true}
          enterNavigatesVerticallyAfterEdit={true}
          processDataFromClipboard={processPastedData}
          suppressClipboardApi={true}
          rowClassRules={rowClassRules}
          selectionColumnDef={selectionColDef}
          onCellEditingStopped={onCellEditingStopped}
          onCellValueChanged={onCellValueChanged}
          onSelectionChanged={onSelectionChanged}
        />
      </div>
      {maxExceeded && (
        <Modal customWrapperStyles={styles.maxExceeded}>
          <p>
            Max List of 200 line items supported. Please remove{' '}
            {rows.length - 200} line items to proceed.
          </p>
          <button onClick={closeModal}>OK</button>
        </Modal>
      )}
    </div>
  )
}

export default memo(CreateTradingList)
